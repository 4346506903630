<template>
  <div class="source">
    <div class="df-row">
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddTag"
          >+ 新增顾客来源</el-button
        >
      </div>
    </div>

    <div class="df-row">
      <div class="df-col tag" v-for="(item, index) in tableList" :key="index">
        <el-tag
          closable
          :disable-transitions="false"
          @close="deleteClientSource(item)"
        >
          {{ item.Name }}
          <i class="el-icon-edit" @click="onEditTag(item)"></i>
        </el-tag>
      </div>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span>
        <el-input
          v-model="submitData.name"
          placeholder="请输入顾客来源"
          clearable
        ></el-input>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveClientSource">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Member from "@/api/member.js";

export default {
  data() {
    return {
      title: "新增顾客来源",
      tableList: [],
      submitData: {
        name: "",
        value: 0,
      },
      showDialog: false,
    };
  },

  mounted() {
    this.getClientSource();
  },

  methods: {
    // 获取顾客来源数据
    async getClientSource() {
      try {
        let { data } = await Member.getClientSource();
        this.tableList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存顾客来源
    async saveClientSource() {
      try {
        let { errcode } = await Member.saveClientSource({
          value: this.submitData.value,
          name: this.submitData.name,
        });
        if (errcode == 0) {
          this.$message.success(
            `${this.submitData.value == -1 ? "保存" : "修改"}成功`
          );
          this.getClientSource();
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 删除顾客来源
    deleteClientSource(event) {
      this.$confirm(
        `此操作将永久删除顾客来源【${event.Name}】, 是否删除?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        try {
          let { errcode } = await Member.deleteClientSource({
            value: event.Value,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getClientSource();
          }
        } catch (error) {
          console.log(error);
        }
      });
    },

    onAddTag() {
      this.submitData = {
        name: "",
        value: -1,
      };
      this.showDialog = true;
    },

    onEditTag(event) {
      this.submitData = {
        name: event.Name,
        value: event.Value,
      };
      this.showDialog = true;
    },

    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
